export const TransactionSource = [
    {
        id: 1,
        value: 'APVAR',
    },
    {
        id: 2,
        value: 'CAPVAR',
    },
    {
        id: 3,
        value: 'Donation',
    },
    {
        id: 4,
        value: 'Admin Entry',
    },
    {
        id: 5,
        value: 'Reward',
    },
    {
        id: 6,
        value: 'Withdraw',
    },
    {
        id: 7,
        value: 'APVAR Group Order Rebate',
    },
    {
        id: 8,
        value: 'CAPVAR Group Order Rebate',
    },
    {
        id: 9,
        value: 'APVAR Group Order Deposit Distribution',
    },
    {
        id: 10,
        value: 'CAPVAR Group Order Deposit Distribution',
    },
    {
        id: 11,
        value: 'APVAR Group Order Deposit Refund',
    },
    {
        id: 12,
        value: 'CAPVAR Group Order Deposit Refund',
    },
    {
        id: 13,
        value: 'APVAR Group Order Deposit',
    },
    {
        id: 14,
        value: 'CAPVAR Group Order Deposit',
    },
    {
        id: 15,
        value: 'Practice Valuation Comparison Group Order Deposit',
    },
    {
        id: 16,
        value: 'Practice Valuation Comparison Group Order Deposit Distribution',
    },
    {
        id: 17,
        value: 'Practice Valuation Comparison Group Order Deposit Refund',
    },
    {
        id: 18,
        value: 'Practice Valuation Comparison Group Order Rebate',
    },
    {
        id: 19,
        value: 'KPI Benchmarking Group Order Deposit',
    },
    {
        id: 20,
        value: 'KPI Benchmarking Group Order Deposit Distribution',
    },
    {
        id: 21,
        value: 'KPI Benchmarking Group Order Deposit Refund',
    },
    {
        id: 22,
        value: 'KPI Benchmarking Group Order Rebate',
    },
    {
        id: 23,
        value: 'Accounting Standardization L12M Data Group Order Deposit',
    },
    {
        id: 24,
        value: 'Accounting Standardization L12M Data Group Order Deposit Distribution',
    },
    {
        id: 25,
        value: 'Accounting Standardization L12M Data Group Order Deposit Refund',
    },
    {
        id: 26,
        value: 'Accounting Standardization L12M Data Group Order Rebate',
    },
    {
        id: 27,
        value: 'Virtual Meeting Group Order Deposit',
    },
    {
        id: 28,
        value: 'Virtual Meeting Group Order Deposit Distribution',
    },
    {
        id: 29,
        value: 'Virtual Meeting Group Order Deposit Refund',
    },
    {
        id: 30,
        value: 'Virtual Meeting Group Order Rebate',
    },
    {
        id: 31,
        value: 'KPI Benchmarking',
    },
    {
        id: 32,
        value: 'Practice Valuation Comparison',
    },
    {
        id: 33,
        value: 'PMG Placement Fee Deposit',
    },
    {
        id: 34,
        value: 'PMG Placement Fee Deposit Refund',
    },
    {
        id: 35,
        value: 'Accounting Standardization L24M Data Group Order Deposit',
    },
    {
        id: 36,
        value: 'Accounting Standardization L24M Data Group Order Deposit Distribution',
    },
    {
        id: 37,
        value: 'Accounting Standardization L24M Data Group Order Deposit Refund',
    },
    {
        id: 38,
        value: 'Accounting Standardization L24M Data Group Order Rebate',
    },
    {
        id: 39,
        value: 'Accounting Standardization L36M Data Group Order Deposit',
    },
    {
        id: 40,
        value: 'Accounting Standardization L36M Data Group Order Deposit Distribution',
    },
    {
        id: 41,
        value: 'Accounting Standardization L36M Data Group Order Deposit Refund',
    },
    {
        id: 42,
        value: 'Accounting Standardization L36M Data Group Order Rebate',
    },
    {
        id: 43,
        value: 'Accounting Standardization YTD + L3CY Data Group Order Deposit',
    },
    {
        id: 44,
        value: 'Accounting Standardization YTD + L3CY Data Group Order Deposit Distribution',
    },
    {
        id: 45,
        value: 'Accounting Standardization YTD + L3CY Data Group Order Deposit Refund',
    },
    {
        id: 46,
        value: 'Accounting Standardization YTD + L3CY Data Group Order Rebate',
    },
    {
        id: 47,
        value: 'PMG Annual Subscription',
    },
    {
        id: 48,
        value: 'Accounting Standardization L12M Deposit Charge',
    },
    {
        id: 49,
        value: 'Accounting Standardization L24M Deposit Charge',
    },
    {
        id: 50,
        value: 'Accounting Standardization L36M Deposit Charge',
    },
    {
        id: 51,
        value: 'Accounting Standardization YTD Deposit Charge',
    },
    {
        id: 52,
        value: 'Maintenance Standardization Monthly Deposit Charge',
    },
    {
        id: 53,
        value: 'Maintenance Standardization Quarterly Deposit Charge',
    },
    {
        id: 54,
        value: 'Maintenance Standardization Semi-Annually Deposit Charge',
    },
    {
        id: 55,
        value: 'Maintenance Standardization Annually Deposit Charge',
    },
    {
        id: 56,
        value: 'Accounting Standardization L12M Final Payment',
    },
    {
        id: 57,
        value: 'Accounting Standardization L24M Final Payment',
    },
    {
        id: 58,
        value: 'Accounting Standardization L36M Final Payment',
    },
    {
        id: 59,
        value: 'Accounting Standardization YTD Final Payment',
    },
    {
        id: 60,
        value: 'Maintenance Standardization Monthly Final Payment',
    },
    {
        id: 61,
        value: 'Maintenance Standardization Quarterly Final Payment',
    },
    {
        id: 62,
        value: 'Maintenance Standardization Semi-Annually Final Payment',
    },
    {
        id: 63,
        value: 'Maintenance Standardization Annually Final Payment',
    },
    {
        id: 64,
        value: 'PMG Placement Rebate',
    },
    {
        id: 65,
        value: 'Standardization Deposit Refund',
    },
    {
        id: 66,
        value: 'Virtual Meeting Charge',
    },
    {
        id: 67,
        value: 'Virtual Meeting Record Charge',
    },
    {
        id: 68,
        value: 'Video Clip Access',
    },
    {
        id: 69,
        value: 'New Clip',
    },
    {
        id: 71,
        value: 'Capvar Deposit',
    },
    {
        id: 73,
        value: 'Capvar Deposit Overcharge Refund',
    },
    {
        id: 74,
        value: 'Gift Card',
    },
    {
        id: 75,
        value: 'Gift Card',
    },
    {
        id: 76,
        value: 'Indirect Reward'
    },
    {
        id: 77,
        value: 'Convert Reward Credits to Flex Credits'
    },
];

export const GiftCardTransactionTypeIds = [74, 75]
