import React, { useState, useEffect } from 'react';
import { Badge, ModalHeader, ModalBody, Button, Label } from 'reactstrap';
import '../../../components/PaginatedTable/PaginatedTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './KnowledgeBaseContentViewModal.scss';
import { faBookmark, faClone, faFile } from '@fortawesome/free-solid-svg-icons';
import { KNOWLEDGE_BASE_TYPES } from '../../../utils/knowledgeBaseConstants';
import createNotification from '../../../utils/createNotification';
import Helpers from '../../../utils/helper';
import bookmarkBorder from '../../../assets/images/bookmark-regular.svg';
import { USER_ROLES } from '../../../utils/constants';
import Rodal from '../../../components/Rodal/Rodal';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';
import { useStateSelector } from '../../../store/selectors';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';

const KnowledgeBaseContentViewModal = (props) => {
    const [fileIcon, setFileIcon] = useState(faFile);
    const [selectedContent, setSelectedContent] = useState(null);
    const [isUserHasAccess, setIsUserHasAccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableKbContent, setIsDisableKbContent] = useState(
        props.isDisableKbContent === true
    );

    useEffect(() => {
        const loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        const summary = JSON.parse(loggedInUserSummary);
        const isUserAdmin = summary.roleId === USER_ROLES.ADMIN;
        const isContentAvailable =
            summary.roleId === USER_ROLES.PRACTICE_OWNER ||
            summary.roleId === USER_ROLES.USER ||
            (summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                summary.associateMember &&
                summary.associateMember.some((x) =>
                    x.practices.some((p) =>
                        p.acceptedAccessRights.includes(
                            ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                        )
                    )
                ));

        setIsAdmin(isUserAdmin);
        setIsUserHasAccess(isContentAvailable && !isDisableKbContent);
        setSelectedContent(props.data);
        if (isContentAvailable && !isDisableKbContent) {
            addToViewed();
        }
        const icon = KNOWLEDGE_BASE_TYPES.find(
            (k) => k.id == props.data.type
        ).icon;
        setFileIcon(icon);
    }, []);

    const axios = useStateSelector((state) => state.core.axios);

    const addToViewed = () => {
        if (isUserHasAccess) {
            axios
                .post('/api/KnowledgeBase/add-to-viewed', {
                    contentId: props.data.id,
                })
                .then()
                .catch((error) => {
                    createNotification(error.message, 'error');
                });
        }
    };

    const openAttachment = () => {
        if (isUserHasAccess || isAdmin) {
            if (selectedContent.type == 7) {
                window.open(selectedContent.link, '_blank');
                return;
            }

            axios
                .get(`/api/KnowledgeBase/${selectedContent.id}/content`)
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while fetching the Knowledge Library Content.'
                        )
                    ) {
                        window.open(response.data.data, '_blank');
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });
        }
    };

    const updateContentState = () => {
        if (isUserHasAccess) {
            const updatedContent = { ...selectedContent };
            updatedContent.isAddedToShelf = !updatedContent.isAddedToShelf;
            setSelectedContent(updatedContent);
            props.updateMyShelf(selectedContent);
        }
    };
    return (
        <>
            {selectedContent ? (
                <Rodal
                    visible={props.isVisible}
                    onClose={() => {
                        props.setIsVisible(false);
                    }}
                    animation={'fade'}
                    showMask={true}
                    className="modal-style content-view-modal announcement-modal view-modal-rodal">
                    <div className="editTitleHeader">
                        <div className="body_white_box associate_member">
                            <ModalHeader>{selectedContent.title}</ModalHeader>
                        </div>

                        <ModalBody className="editTitleBody">
                            <div
                                className="associate_form announcement_form"
                                style={{ borderBottom: 0 }}>
                                <div className="edit-title-image-div">
                                    <div className="edit-tags">
                                        {selectedContent.topics.map((t) => (
                                            <Badge>{t.tag}</Badge>
                                        ))}
                                    </div>
                                    <div className="edit-image-icon">
                                        {selectedContent.price ? (
                                            <Label>
                                                Paid -{' '}
                                                {Helpers.GetCurrencyFormatWithCents(
                                                    selectedContent.price
                                                )}
                                            </Label>
                                        ) : (
                                            <Label>Free</Label>
                                        )}
                                        <FontAwesomeIcon
                                            icon={fileIcon}
                                            className={`file-icon ${
                                                selectedContent.price
                                                    ? ''
                                                    : 'cursor-pointer'
                                            }`}
                                            onClick={() => {
                                                if (!selectedContent.price) {
                                                    openAttachment();
                                                }
                                            }}
                                        />
                                        {selectedContent.isAddedToShelf ? (
                                            <FontAwesomeIcon
                                                icon={faBookmark}
                                                className="file-icon cursor-pointer"
                                                onClick={updateContentState}
                                            />
                                        ) : (
                                            <img
                                                className={`file-icon ${
                                                    selectedContent.price
                                                        ? ''
                                                        : 'cursor-pointer'
                                                }`}
                                                src={bookmarkBorder}
                                                onClick={() => {
                                                    if (
                                                        !selectedContent.price
                                                    ) {
                                                        updateContentState();
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="edit-title-dec-wrapper">
                                    <label>Introduction</label>
                                    <div className="edit-title-intro-text-wrapper">
                                        <p className="deleteModalBodyP">
                                            {selectedContent.introText}
                                        </p>
                                    </div>
                                </div>
                                {selectedContent.price ? (
                                    <div className="purchase-btn-container">
                                        <ButtonLoader
                                            buttonText={'Purchase'}
                                            loaderButtonText={''}
                                            disabled={false}
                                            isLoading={isLoading}
                                            onClick={() => {}}
                                            className={
                                                isLoading
                                                    ? 'btn save-btn loader-submit'
                                                    : 'btn save-btn'
                                            }></ButtonLoader>
                                    </div>
                                ) : (
                                    <div className="edit-title-dec-wrapper">
                                        <label>Source File</label>
                                        <div className="file-info-wrapper">
                                            <FontAwesomeIcon
                                                icon={fileIcon}
                                                className="file-icon  cursor-pointer"
                                                onClick={() => {
                                                    openAttachment();
                                                }}
                                            />
                                            <p
                                                className="file-name cursor-pointer"
                                                onClick={() => {
                                                    openAttachment();
                                                }}>
                                                {selectedContent.type == 7
                                                    ? selectedContent.link
                                                    : selectedContent.fileName}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                    </div>
                </Rodal>
            ) : (
                []
            )}
        </>
    );
};

export default KnowledgeBaseContentViewModal;
